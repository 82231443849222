import React from 'react'
import Hit from './hit'
import Form from './form'
import CurrentStateResults from './state-results'
import Text from '../ui/text'
import { FiSearch } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { useMedia } from 'react-use-media'
import { Container, Row, Col } from '../ui/wrapper'
import { size } from '../../theme'
import {
  Hits,
  SearchBox,
  Pagination,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom'
import { SearchWrapper, SearchButton } from './styles'

const Search = ({ searchResults, query }) => {
  const isMobile = useMedia({ maxWidth: size.xxsmall })
  const hasResults = searchResults && searchResults.nbHits !== 0

  return (
    <SearchWrapper className="container-xl">
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <SearchButton>
              Zoeken
              <SearchBox
                submit={<FiSearch role="img" title="Zoeken" />}
                reset={<MdClose role="img" title="Reset" />}
                defaultRefinement={query ? query : ''}
                translations={{
                  placeholder: isMobile
                    ? 'Zoeken'
                    : 'Waar bent u naar op zoek?',
                }}
              />
            </SearchButton>

            {hasResults ? (
              <>
                <Configure hitsPerPage={10} />
                <Hits hitComponent={Hit} />
                <Pagination showFirst={false} showLast={false} />
              </>
            ) : (
              <Text align="center" mb="80px" as="div" fontSize="20px">
                Geen resultaten
              </Text>
            )}
          </Col>
        </Row>
      </Container>
    </SearchWrapper>
  )
}

export default connectStateResults(Search)
export { Hit, CurrentStateResults, Form }
