import React, { useState } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, ScrollTo } from 'react-instantsearch-dom'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Search, { CurrentStateResults } from '../components/search'

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY,
)

const IndexPage = ({ location }) => {
  const [flyoutOpen, setFlyoutOpen] = useState(false)


  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <InstantSearch indexName="prod_RWB" searchClient={searchClient}>
      <ScrollTo>
        <SEO
          title="Zoekresultaten"
          description="Zoekresultaten pagina"
          url="https://www.west-brabant.eu/zoeken"
        />

        <Header
          transparent
          flyoutOpen={flyoutOpen}
          flyoutHandler={flyoutHandler}
        />
        <main id="main" className="site-wrapper-reveal">
          <CurrentStateResults />
          <Search
            query={
              location && location.state && location.state.query
                ? location.state.query
                : ''
            }
          />
        </main>
        <Footer />
      </ScrollTo>
    </InstantSearch>
  )
}

export default IndexPage
