import React from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import Heading from '../ui/heading'
import { Container, Row, Col } from '../ui/wrapper'

const StateResults = ({ searchResults }) => (
  <Container>
    <Row noGutter={true} justify="center">
      <Col xl={5} lg={6} ml="15px" mr="15px">
        <Heading
          as="h1"
          textalign="center"
          mb="40px"
          mt="80px"
          pl="15px"
          pr="15px"
        >
          {searchResults && searchResults.query !== '' ? (
            <>
              Zoekresultaten voor:
              <br />"{searchResults.query}"
            </>
          ) : (
            <>Zoekresultaten</>
          )}
        </Heading>
      </Col>
    </Row>
  </Container>
)

const CurrentStateResults = connectStateResults(StateResults)
export default CurrentStateResults
