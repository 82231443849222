import styled, { css } from 'styled-components'
import Text from '../ui/text'
import { device } from '../../theme'

export const SearchFromWrap = styled.div`
  position: relative;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;

  label {
    display: none;
  }

  input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    border-radius: 0;
    padding: 15px 50px 15px 0;
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    font-size: 72px;
    height: 100px;
    line-height: 100px;

    @media ${device.medium} {
      font-size: 52px;
    }

    @media ${device.small} {
      font-size: 48px;
    }

    @media ${device.xsmall} {
      font-size: 32px;
    }

    &:focus {
      border-bottom: 2px solid ${(props) => props.theme.colors.theme};
      color: ${(props) => props.theme.colors.white} !important;
    }

    &::placeholder,
    &::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.7) !important;
    }

    &:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }
`

export const SearchWrapper = styled.div(
  ({ theme: { colors, fontFamily } }) => css`
    background-color: ${colors.background};

    em {
      background-color: #ffffcc;
      font-style: normal;
    }

    .ais-Pagination {
      margin-top: 80px;
      padding-bottom: 80px;
    }

    .ais-Pagination-list {
      display: flex;
      justify-content: center;

      > li > a,
      > li > span {
        color: ${colors.textColor};
        font-family: ${fontFamily.text};
        font-size: 14px;
        font-weight: 700;
        height: 41px;
        letter-spacing: 2px;
        line-height: 41px;
        min-width: 41px;
        text-align: center;
        text-transform: uppercase;

        :hover {
          color: ${colors.theme};
          text-decoration: underline;
        }

        &.ais-Pagination-link--selected {
          color: ${colors.theme};
          background: #f6f2ed;
        }
      }

      .ais-Pagination-item--nextPage,
      .ais-Pagination-item--previousPage {
        a {
          font-size: 28px;
          font-weight: 500;
          line-height: 35px;
        }
      }
    }

    .ais-Pagination-item--disabled {
      display: none;
    }
  `,
)

export const SearchButton = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 22px 22px 22px 72px;
  font-size: 20px;
  background: ${(props) => props.theme.colors.white};
  border: none;
  z-index: 4;
  border-radius: 100px;
  text-indent: -99999999px;

  svg {
    color: ${(props) => props.theme.colors.theme};
    font-size: 22px;
  }

  > *,
  form,
  input {
    width: 100%;
    text-indent: 0;
    z-index: 2;
  }

  form,
  input {
    height: 24px;
  }

  input {
    border: none;
    padding: 0;
    line-height: 24px;
  }

  button {
    background: none;
    border: none;
  }

  button[type='submit'],
  button[type='reset'] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    svg {
      color: ${(props) => props.theme.colors.theme};
      font-size: 22px;
    }
  }

  button[type='reset'] {
    right: 22px;
  }

  button[type='submit'] {
    left: 22px;
    padding-top: 0;
  }

  input[type='search']::-ms-clear,
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`

export const Card = styled.div`
  position: relative;
  margin-top: 30px;
  padding: 32px 35px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 26px;
    font-weight: normal;
    line-height: 1.6em;
    margin-bottom: 12px;
    color: ${(props) => props.theme.colors.textDark};
  }

  > a {
    transform: none;

    span.btn-icon {
      display: none;
    }
  }

  > a::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const CategoryWrap = styled(Text)`
  margin-bottom: 0;
  font-size: 0.85rem;
  line-height: ${(props) => props.theme.lineHieghts.small};
  font-family: ${(props) => props.theme.fontFamily.heading};
  font-weight: 300;
  text-transform: capitalize;

  @media ${device.small} {
    font-size: ${(props) => props.theme.fontSizesSmall.small};
    line-height: ${(props) => props.theme.lineHieghtsSmall.small};
  }
`
